import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleLoadingStrategyService } from './modules/utils/service/module-loading-strategy.service';
import { ActiveSessionGuard } from './utils/active-session.guard';
import { OnboardingGuard } from './utils/onboarding.guard';
import { ValidTokenGuard } from './utils/valid-token.guard';
import { MicrosoftVerificationComponent } from './modules/microsoft-verification/microsoft-verification.component';

export const routes: Routes = [
  {
    path: '.well-known/microsoft-identity-association.json',
    component: MicrosoftVerificationComponent,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'onboarding',
    canActivate: [OnboardingGuard],
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'welcome',
    canActivate: [ValidTokenGuard],
    loadChildren: () =>
      import('./modules/welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: 'myprojects',
    canActivate: [ValidTokenGuard],
    loadChildren: () =>
      import('./modules/my-projects-onboarding/my-projects-onboarding.module').then((m) => m.MyProjectsOnboardingModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: '',
    canActivate: [ActiveSessionGuard],
    loadChildren: () =>
      import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: ModuleLoadingStrategyService,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
