import { PaginatorResponse } from "src/app/modules/utils/paginator.response";
import { TemplateConfiguration } from "./templateConfiguration.model";
import { Attachment } from "../../accounts-receivable/model/paymentOrder.model";
import { TranslateService } from "@ngx-translate/core";

export class Template {
  id: string;
  activeVersionNumber: string;
  registerDate: string;
  registrationBoUserId: string;
  projectId: string;
  active: boolean;
  status: string;
  name: string;
  channelType: string;
  parentTemplateId: string;
  configuration: TemplateConfiguration = new TemplateConfiguration();
  hasFooter: boolean | null;
  onlyForGeneralConfiguration : boolean | null;
  onlyForCampaign : boolean | null;
  onlyForCommunicationFlow : boolean | null;
  assigned : boolean;
  recipientType: string;
  templateAttachmentIds : string[];
  sendFormButton: boolean;
  externalProviderId : string;
  externalProviderStatus : string;
  staticAttachmentsUrl : Attachment[];
  clientAttachmentConfiguration : ClientAttachmentConfiguration;
  additional_data : AdditionalData;
  clientId: string | null;
  type : string | null;
}
export class TemplateStatisticsData{
  sentEventTotalCount:number;
  deliveredEventTotalCount:number;
  sentEventDestinationCount:number;
  deliveredDestinationEventCount:number;
  openEventDestinationCount:number;
  clickEventDestinationCount:number;
  failedEventDestinationCount:number;
}

export class AdditionalData{
  vozyAgentCode:string;
}

export enum VozyAgentCode {
  COBRANZA_PAGO_VENCIDO = 'COBRANZA_PAGO_VENCIDO',
  COBRANZA_PAGO_HOY = 'COBRANZA_PAGO_HOY',
  COBRANZA_PARCIAL = 'COBRANZA_PARCIAL',
  COBRANZA_MORA = 'COBRANZA_MORA',
  MOONFLOW_BIENVENIDA = 'MOONFLOW_BIENVENIDA',
}

export class TemplateWithStatisticsPageable{
  content : TemplateWithStatistics[];
}

export class TemplateWithStatistics extends Template{
  data : TemplateStatisticsData;
  events: AssignmentDetail[];
  communicationFlows: AssignmentDetail[];
  campaigns: AssignmentDetail[];
}

export class AssignmentDetail{
  id:string;
  name:string;
}

export class ClientAttachmentConfiguration{
  clientAttachment1:boolean;
  clientAttachment2:boolean;
}

export class TemplatePaginationResponse extends PaginatorResponse{
  content: Template[];
}

export class TemplateTest extends Template {
  destination?: string | null;
  templateGeneralConfigurationLogId? : string | null;
}

export enum ChannelType {
  EMAIL_CHANNEL_TYPE = 'E',
  SMS_CHANNEL_TYPE = 'S',
  WHATSAPP_CHANNEL_TYPE = 'W',
  WHATSAPP_CLOUD_API_CHANNEL_TYPE = 'A',
  PHONE_CALL = 'C',
  CHANNEL_TYPE_TRUNK_CALL = "P",
  FIELD_MANAGEMENT = 'F',
  VOICE_ASSISTANT = 'I',
  NOTE = 'N',
}

export enum TemplateExternalProviderStatus{
  REVIEW_EXTERNAL_PROVIDER_STATUS = 'R',
  APPROVED_EXTERNAL_PROVIDER_STATUS = 'A',
  FAILED_EXTERNAL_PROVIDER_STATUS = 'F',
  DELETED_EXTERNAL_PROVIDER_STATUS = 'D',
  DISABLED_EXTERNAL_PROVIDER_STATUS = 'I',
}

export enum RecipientType {
  CLIENT_RECIPIENT_TYPE = 'C',
  REFERENTS_RECIPIENT_TYPE = 'R',
  GUARANTORS_RECIPIENT_TYPE = 'G',
  BUSINESS_CONTACT_RECIPIENT_TYPE = 'B'
}

export enum TagShowComponentEnum{
  TEMPLATE_SUBJECT = 'TS',
  TEMPLATE_BODY = 'TB',

  TEMPLATE_EMAIL_CHANNEL_TYPE = 'E',
  TEMPLATE_SMS_CHANNEL_TYPE = 'S',
  TEMPLATE_WHATSAPP_CHANNEL_TYPE = 'W',
  TEMPLATE_WHATSAPP_CLOUD_API_CHANNEL_TYPE = 'A',
  TEMPLATE_PHONE_CALL = 'CL',
  TEMPLATE_FIELD_MANAGEMENT = 'F',

  ATTACHMENT_SUBJECT = 'AS',
  ATTACHMENT_BODY = 'AB',
  CONVERSATION_REPONSE = 'CR',
  CONVERSATION_SUBJECT_REPONSE = 'CS',

  FOR_ACCOUNT_STATUS='FAS',

  FOR_PAYMENT_METHOD='FPM'
}

export enum TemplateType {
  PROJECT_TYPE = "P",
  CREDIT_SCHEDULE_TYPE = "CS",
}

export enum TemplateStatus {
  CREATED_STATUS = "C",
  DELETED_STATUS = "D",
}