import { EconomicSector } from './economic-sector.models';

export class AccountSettings {
  name: string;
  legalName: string;
  economicSectorId: number;
  taxIdentification: string;
  taxAddress: string;
  countryPhoneNumber: string;
  phoneNumber: string;
  currency: string;
  timeZone: string;
  language: string;
  websiteUrl: string;
  companyBannerUrl: string;
  apikey: string;
  logoUrl?: string;
  countryId: string;
  extraData: ExtraData;
  validations : Validations;
  legalConstitutionValidationFile : string;
  legalRepresentativeValidationFile : string;
  shareholdingValidationFile : string;
  taxCertificateValidationFile : string;
  bankInformation : BankInformation[];
  externalPaymentEnable : boolean;
}

export class ExtraData {

  acceptTipsAndNews: boolean;
  acceptTermsConditions: boolean;
  clientsType: string;
  activeClients: string;
  frequentPayPeriod: string;
  delay30DaysPercentage: string;
  primaryColor: string;
  secondaryColor: string;
  adviser: boolean;
  companyDescription: string;
  chargeEntity: string;
}

export class Validations{
  legalInformationStatus : string;
  taxInformationStatus : string;
  bankInformationStatus : string;
}

export class BankInformation{
  bankId : number;
  currency : string;
  accountType : string;
  accountNumber : string;
  validationStatus : string;
  validationFile : string;
}

export enum BankAccountType{
  SAVINGS_ACCOUNT = 'S',
  CHECKING_ACCOUNT = 'C',
}

export enum ValidationsStatus{
  PENDING_STATUS = 'P',
  SUCCESS_STATUS = 'S',
  FAILED_STATUS = 'F',
}
