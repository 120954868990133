export class CreditVerificationConfiguration{
  active : boolean;
  configuration : Configuration[];
}

export class Configuration{
  priority : number;
  verificationType : string;
  day : number | null;
  executionType : string;
  required : boolean;
  active : boolean;
}

export enum CreditVerificationTypeEnum{
  REFERENCE="REFERENCE",
  GUARANTOR="GUARANTOR",
  SCHEDULE_SUBMISSION="SCHEDULE_SUBMISSION",
  WELCOME_CALL="WELCOME_CALL"
}

export enum CreditExecutionTypeEnum{
  AUTOMATIC="A",
  MANUAL="M",
}

export enum CreditVerificationProcessStatusEnum{
  ALERT="A",
  OK="S",
  PROCESSING="P",
  DENIED="D"
}

export enum CreditVerificationDestinationEnum {
  REF1 = "REF1",
  REF2 = "REF2",
  GAR1 = "GAR1",
  GAR2 = "GAR2",
  EMAIL1 = "EMAIL1",
  EMAIL2 = "EMAIL2",
  CELLPHONE1 = "CELLPHONE1",
  CELLPHONE2 = "CELLPHONE2"
}


export enum VerificationAlert{
  DOCUMENT_NUMBER_NOT_MATCHING = "DOCUMENT_NUMBER_NOT_MATCHING",
  ADDRESS_NOT_MATCHING_ALERT = "ADDRESS_NOT_MATCHING",
  CONTACT_NOT_CONFIRMED_ALERT = "CONTACT_NOT_CONFIRMED",
  UNKNOWLEDGE_ALERT = "UNKNOWLEDGE",
  ANOTHER_PERSON_ALERT = "ANOTHER_PERSON",
  PRODUCT_FOR_PERSONAL_USE_ALERT = "PRODUCT_FOR_PERSONAL_USE",
  DISAGREEMENT_ALERT = "DISAGREEMENT",
  IDENTITY_NOT_VALIDATED_ALERT = "IDENTITY_NOT_VALIDATED",
  NOT_CONTACTED_DESTINATION_RESULT = "NOT_CONTACTED",
  VALIDATED_DESTINATION_RESULT = "VALIDATED",
  IDENTITY_NOT_VALIDATED_DESTINATION_RESULT = "IDENTITY_NOT_VALIDATED",
  DISAGREEMENT_DESTINATION_RESULT = "DISAGREEMENT",
  USE_BY_THIRD_PARTY_DESTINATION_RESULT = "USE_BY_THIRD_PARTY",
  NON_WORK_USE_DESTINATION_RESULT = "NON_WORK_USE",
  UNKNOWN_OWNER_DESTINATION_RESULT = "UNKNOWN_OWNER",
  UNEMPLOYED_DESTINATION_RESULT = "UNEMPLOYED",
  UNKNOWN_ADDRESS_DESTINATION_RESULT = "UNKNOWN_ADDRESS",
  UNKNOWN_MARITAL_STATUS_DESTINATION_RESULT = "UNKNOWN_MARITAL_STATUS",
}
