export class Country {
    id: string;
    name: string;
    translationKey: string;
    phoneCode: string;
    isoCode : string;
    flagUrl : string;
}
  
export enum CountryCode{
    ARGENTINA_COUNTRY_CODE = "+54",
    MEXICO_COUNTRY_CODE = "+52",
    PERU_COUNTRY_CODE = "+51",
    PANAMA_COUNTRY_CODE = "+507",
}