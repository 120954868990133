import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-microsoft-verification',
  templateUrl: './microsoft-verification.component.html',
  styleUrls: ['./microsoft-verification.component.scss']
})
export class MicrosoftVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  obj = {
    associatedApplications:[
      {
        "applicationId": "fda999e8-42c7-4ef4-84f1-67ca40c6e4b3"
      }
    ]
  }

}
