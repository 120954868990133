import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslationService} from './modules/i18n';
// language list
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as chLang} from './modules/i18n/vocabs/ch';
import {locale as esLang} from './modules/i18n/vocabs/es';
import {locale as jpLang} from './modules/i18n/vocabs/jp';
import {locale as deLang} from './modules/i18n/vocabs/de';
import {locale as frLang} from './modules/i18n/vocabs/fr';
import {locale as ptLang} from './modules/i18n/vocabs/pt';
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  TITLE_DEFAULT = 'SEO.TITLE.DEFAULT';
  META_DEFAULT = 'SEO.META.DEFAULT';
  loadingView : boolean = true;

  constructor(
    private translationService: TranslationService,
    private translate: TranslateService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private cd : ChangeDetectorRef
    ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang,
      ptLang
    );
  }

  ngOnInit() {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = ''+this.TITLE_DEFAULT;
        let routeMeta = ''+this.META_DEFAULT;
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) routeTitle = route!.snapshot.data['title'];
        if (route.snapshot.data['meta']) routeMeta = route!.snapshot.data['meta'];
        return {
          title : routeTitle,
          meta : routeMeta
        };
      })
    )
    .subscribe((config : {
      title : string;
      meta : string;
    }) => {
      if (config.title){
        if(this.titleService.getTitle() != config.title) {
          this.titleService.setTitle(this.translate.instant(config.title));
          this.cd.markForCheck();
        }
      }
      if (config.meta) this.metaService.updateTag( { name:'description',content:this.translate.instant(config.meta)});
    });

    this.router.events.subscribe((event: Event) => {
      if(event instanceof NavigationStart && event.url.indexOf("strategies") != -1 && event.navigationTrigger != "imperative") this.loadingView = true;
      else if(event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) this.loadingView = false;
    });

  }
}
