import { Attachment } from "./paymentOrder.model";
import { RecoveryCampaignCustom } from "./recoveryCampaignCustom.model";
import { RecoveryCampaignPaymentAction } from "./recoveryCampaignPaymentAction.model";

export class RecoveryCampaign{
  id : string;
  projectId : string;
  clientId : string;
  campaignId : string;
  status : string;
  recoveryAmount : number;
  discountType : string;
  discountValue : number;
  currency : string;
  recoveryTotalAmount : number;
  balance : number;
  expirationDate : string;
  referencePaymentCode : string;
  registerDate : string;
  origin : string;
  registrationBoUserId : string;
  massiveProcessId : string;
  attachment1: Attachment | null;
  attachment2: Attachment | null;
}

export class RecoveryCampaignWithSelector extends RecoveryCampaign{
  selected? : boolean;
}

export class RecoveryCampaignCreateDTO{
  documentTypeId : number;
  documentNumber : string;
  projectId : string;
  campaignId : string;
  recoveryAmount : string;
  discountType : string;
  discountValue : string;
  currency : string;
  balance : string;
  expirationDate : string;
  referencePaymentCode : string;
  origin : string;
  registrationBoUserId : string;
  massiveProcessId : string;
  externalClientId : string;
  attachment1: Attachment | null;
  attachment2: Attachment | null;
}

export class RecoveryCampaignUpdateDTO{
  id: string;
  documentTypeId : number;
  documentNumber : string;
  projectId : string;
  campaignId : string;
  recoveryAmount : string;
  discountType : string;
  discountValue : string;
  currency : string;
  balance : string;
  expirationDate : string;
  referencePaymentCode : string;
  origin : string;
  registrationBoUserId : string;
  massiveProcessId : string;
  externalClientId : string;
  attachment1: Attachment | null;
  attachment2: Attachment | null;
}

export enum StatusRecoveryCampaign{
  STATUS_FINISHED = 'F',
  STATUS_PAID = 'P',
  STATUS_ACTIVE = 'A',
}

export class RecoveryCampaignBalance{
  balance : number;
  currency : string;
}

export enum DiscountType{
  DISCOUNT_TYPE_PERCENTAGE = 'P',
  DISCOUNT_TYPE_AMOUNT = 'A',
}

export class PaymentActionWithRecoveryCampaign{
  recoveryCampaignPaymentAction: RecoveryCampaignPaymentAction;
  recoveryCampaignCustom: RecoveryCampaignCustom;
}
