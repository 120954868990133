export class Onboarding{
    id : string;
    status : string;
    projectId : string;
    currentStep : number;
    registerDate : string;
    startConfigurationDate : string;
}

export enum OnboardingStep {
    REVIEWS_STEP = 1,
    ACCOUNT_INFORMATION_STEP = 2,
    DISPLAY_SETTINGS_STEP = 3,
    PAYMENT_METHODS_CONFIGURATION_STEP = 4,
    ASYNC_CONFIGURATION_STEP = 5,
    READY_STEP = 6,
}

export enum OnboardingStatus {
    PENDING_STATUS = "P",
    CONFIGURED_STATUS = "C",
    FINISHED_STATUS = "F",
    NOT_CONFIGURED_STATUS = "N",
}
