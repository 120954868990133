export class TemplateConfiguration {
  subject: string | null;
  body: string;
  headerImg: string | null;
  whatsappButtons: WhatsappButton[];
  payButtonTextColor: string;
  payButtonBackgroundColor: string;
  contactResponseActionButtonTextColor: string;
  contactResponseButtonBackgroundColor: string;
  paymentOrderTableTagConfiguration: PaymentOrderTableTagConfiguration[] | null;
}

export class WhatsappButton {
  type: string;
  buttonText: string;
  url: string;
}

export class PaymentOrderTableTagConfiguration {
  headerCode: string | null;
  headerText: string | null;
}