import { PaginatorResponse } from "src/app/modules/utils/paginator.response";
import { DocumentType } from "../../accounts-receivable/model/documentType.model";

export class CallManagement{
    id : string;
    status : string;
    communicationId : string;
    parentCallManagementId : string;
    clientId : string;
    projectId : string;
    registerDate : string | Date | null;
    callDate : string | Date | null;
    assignedBoUserId : string;
    lastActionType : string;
    lastActionDate : string | Date | null;
    actions : CallManagementAction[];
    phoneNumber : string | null;
    paymentOrderReference: PaymentOrderReference[];
}

export class PaymentOrderReference{
    paymentOrderId: string;
    reference: string;
}

export class CallManagementPageableData extends PaginatorResponse {
    content: CallManagementCustom[];
}

export class CallManagementCustom extends CallManagement {
    clientId : string;
    countryCode : string;
    documentTypeId : number;
    documentNumber : string;
    name : string;
    lastName : string;
    cellphone1 : string;
    selectedCellPhone1:boolean;
    cellphone2 : string;
    selectedCellPhone2:boolean;
    cellphone3 : string;
    cellphone4 : string;
    cellphone5 : string;
    email1 : string;
    email2 : string;
    externalClientId : string;
    documentType? : DocumentType;
    paymentOrderDueDate : string | Date | null;
    daysLate? : number | null;
    assignedBoUser: string;
    disabledButtonCall:boolean;
    balance : CallManagementBalance[];
    balanceAsString? : string | null;
    phoneNumber : string | null;
}

export class CallManagementAction{
    actionType : string;
    actionDate : string | Date | null;
    phoneNumber : string;
    boUserId : string;
    communicationId: string;
}

export class CallManagementBalance{
    currency : string;
    balance : string;
}

export enum CallManagementActionType {
    ASSIGN_USER_ACTION_TYPE = "assignUser",
    PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE = "paymentInReviewOrCommitment",
    PAYMENT_COMMITMENT_ACTION_TYPE = "paymentCommitment",
    PAYMENT_IN_REVIEW_ACTION_TYPE = "paymentInReview",
    RETRY_CALL_ACTION_TYPE = "retryCall",
    REFUSE_TO_PAY_ACTION_TYPE = "refuseToPay",
    CANT_PAY_ACTION_TYPE = "cantPay",
    WRONG_PHONE_NUMBER_ACTION_TYPE = "wrongPhoneNumber",
    NO_RESPONSE_ACTION_TYPE = "noResponse",
    CALL_ACTION_TYPE = "call",
    PAYMENT_RENEGOTIATION_TYPE = "paymentRenegotiation",
    MESSAGE_IN_MAILBOX_TYPE = "messageInMailbox",
    CONTACT_WITH_THIRD_PARTY_TYPE = "contactWithThirdParty",
    INBOUND_PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE = "inboundPaymentInReviewOrCommitment",
    INBOUND_PAYMENT_RENEGOTIATION_TYPE = "inboundPaymentRenegotiation",
    INBOUND_REQUESTED_INFORMATION_TYPE = "inboundRequestedInformation",
    INBOUND_CLAIM_TYPE = "inboundClaim",
    REFINANCE_TYPE = "refinance",
    INBOUND_REFINANCE_TYPE = "inboundRefinance",
    INBOUND_PAYMENT_COMMITMENT_ACTION_TYPE = "inboundPaymentCommitment",
    INBOUND_PAYMENT_IN_REVIEW_ACTION_TYPE = "inboundPaymentInReview",
    PAYMENT_MADE_TYPE = "paymentMade",
    INBOUND_PAYMENT_MADE_TYPE = "inboundPaymentMade",
    IN_PROCESS_TYPE = "inProcess",
    COMMUNICATION_REMINDER_TYPE = "communicationReminder",
    PAUSED_COMMUNICATION_CONTACT_ACTION_TYPE  = "pausedCommunicationContact",
    PAUSED_COMMUNICATION_NO_CONTACT_ACTION_TYPE = "pausedCommunicationNoContact",
    PAUSED_COMMUNICATION_INBOUND_ACTION_TYPE = "pausedCommunicationInbound"
}
