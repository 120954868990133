import { PaginatorResponse } from "src/app/modules/utils/paginator.response";
import { DocumentType } from "../../accounts-receivable/model/documentType.model";

export class FieldManagement{
    id : string;
    status : string;
    communicationId : string;
    parentFieldManagementId : string;
    clientId : string;
    projectId : string;
    registerDate : string | Date | null;
    managementDate : string | Date | null;
    assignedBoUserId : string;
    lastActionType : string;
    lastActionDate : string | Date | null;
    actions : FieldManagementAction[];
    paymentOrderReference: PaymentOrderReference[];
}

export class PaymentOrderReference{
    paymentOrderId: string | null;
    recoveryCampaignId: string | null;
    reference: string | null;
}


export class FieldManagementAction{
    actionType : string;
    actionDate : string | Date | null;
    phoneNumber : string;
    boUserId : string;
    communicationId: string;
    note: string;
    attachmentUrls: string[];
}

export enum FieldManagementActionType {
    ASSIGN_USER_ACTION_TYPE = "assignUser",
    PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE = "paymentInReviewOrCommitment",
    PAYMENT_COMMITMENT_ACTION_TYPE = "paymentCommitment",
    PAYMENT_IN_REVIEW_ACTION_TYPE = "paymentInReview",
    RETRY_CALL_ACTION_TYPE = "retryCall",
    REFUSE_TO_PAY_ACTION_TYPE = "refuseToPay",
    CANT_PAY_ACTION_TYPE = "cantPay",
    WRONG_ADDRESS_ACTION_TYPE = "wrongAddress",
    NO_ANSWER_ACTION_TYPE = "noAnswer",
    CALL_ACTION_TYPE = "call",
    PAYMENT_RENEGOTIATION_TYPE = "paymentRenegotiation",
    MESSAGE_IN_MAILBOX_TYPE = "messageInMailbox",
    CONTACT_WITH_THIRD_PARTY_TYPE = "contactWithThirdParty",
    INBOUND_PAYMENT_IN_REVIEW_OR_COMMITMENT_ACTION_TYPE = "inboundPaymentInReviewOrCommitment",
    INBOUND_PAYMENT_RENEGOTIATION_TYPE = "inboundPaymentRenegotiation",
    INBOUND_REQUESTED_INFORMATION_TYPE = "inboundRequestedInformation",
    INBOUND_CLAIM_TYPE = "inboundClaim",
    REFINANCE_TYPE = "refinance",
    INBOUND_REFINANCE_TYPE = "inboundRefinance",
    RETRY_MANAGEMENT_ACTION_TYPE = "retryManagement",
    UPDATE_DATA_ACTION_TYPE = "updateData",
}